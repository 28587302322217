import React, { useEffect, useState } from "react";
import Grid from "@mui/material/Grid2";
import Controls from "../../components/assests/Controls";
import { UseForm, Form } from "../../components/assests/UseForm";
import AppTheme from "../../Theme/AppTheme";
import CircularProgress from "@mui/material/CircularProgress";
import Backdrop from "@mui/material/Backdrop";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import axios from "axios";

const proxy = process.env.REACT_APP_PROXY;

const KPIFValues = {
  KRI_Id: 0,
  KRI_Name: "",
  Threshold_Min: "",
  Threshold_Max: "",
  Objective_Id: "",
  Risk_Level: "",
  Mitigation: "",
};
dayjs.extend(utc);
export default function KPIForm(props) {
  const { recordForEdit, onSubmit, showNotification } = props;
  const [loading, setLoading] = useState(false);
  const [objectives, setObjectives] = useState([]);

  const { values, setValues, errors, handleInputChange, resetForm } = UseForm(
    recordForEdit || KPIFValues,
    true
  );

  useEffect(() => {
    const fetchObjectives = async () => {
      try {
        const response = await axios.get(`${proxy}/objective/getAllObjectives`);
        setObjectives(response.data.data);
      } catch (error) {}
    };
    fetchObjectives();
  }, []);

  useEffect(() => {
    if (recordForEdit) {
      setValues({
        KRI_Id: recordForEdit.KRI_Id,
        KRI_Name: recordForEdit.KRI_Name,
        Threshold_Min: recordForEdit.Threshold_Min,
        Threshold_Max: recordForEdit.Threshold_Max,
        Objective_Id: recordForEdit.Objective_Id,
        Risk_Level: recordForEdit.Risk_Level,
        Mitigation: recordForEdit.Mitigation,
      });
    } else {
      resetForm();
    }
  }, [recordForEdit, setValues]);

  const handleSubmit = async (event) => {
    event.preventDefault();
    setLoading(true);
    const KRIData = {
      KRI_Id: recordForEdit ? recordForEdit.KRI_Id : null,
      KRI_Name: values.KRI_Name,
      Threshold_Min: values.Threshold_Min,
      Threshold_Max: values.Threshold_Max,
      Objective_Id: values.Objective_Id,
      Risk_Level: values.Risk_Level,
      Mitigation: values.Mitigation,
    };
    try {
      if (recordForEdit) {
        const res = await axios.put(
          `${proxy}/kri/updateKRI/${recordForEdit.KRI_Id}`,
          KRIData
        );
      } else {
        const res = await axios.post(`${proxy}/kri/saveKRI`, KRIData);
      }
      onSubmit();
      showNotification({
        isOpen: true,
        message: recordForEdit
          ? "KRI updated successfully!"
          : "KRI submitted successfully!",
        type: "success",
      });
      props.setOpenPopup(false);
    } catch (error) {
      if (error.response.status === 409) {
        showNotification({
          isOpen: true,
          message: "KRI already exists.",
          type: "warning",
        });
      } else {
        showNotification({
          isOpen: true,
          message: "Failed to submit KRI data.",
          type: "error",
        });
      }
    } finally {
      setLoading(false); // Set loading back to false when done
    }
  };
  const handleReset = () => {
    resetForm();
  };

  return (
    <AppTheme>
      <Backdrop
        open={loading}
        style={{
          color: "#fff",
          zIndex: 1300,
        }}
      >
        <CircularProgress color="inherit" size="60px" />
      </Backdrop>
      <Form onSubmit={handleSubmit}>
        <Grid container>
          <Grid item xs={12}>
            <FormControl fullWidth variant="filled" margin="normal">
              <InputLabel>Objective</InputLabel>
              <Select
                placeholder="Objective"
                name="Objective_Id"
                value={values.Objective_Id || ""}
                onChange={(e) =>
                  setValues({ ...values, Objective_Id: e.target.value })
                }
                sx={{ minWidth: 600 }}
                margin="normal"
              >
                {objectives.map((objective) => (
                  <MenuItem
                    key={objective.Strategic_Objectives_ID}
                    value={objective.Strategic_Objectives_ID}
                  >
                    {objective.Description}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <Controls.Input
              required
              name="KRI_Name"
              placeholder="KRI Name"
              value={values.KRI_Name}
              onChange={handleInputChange}
              error={errors.KRI_Name}
              fullWidth
              autoFocus
              margin="normal"
            />
            <Controls.Input
              required
              name="Threshold_Min"
              placeholder="Threshold Min"
              value={values.Threshold_Min}
              onChange={handleInputChange}
              error={errors.Threshold_Min}
              fullWidth
              autoFocus
              margin="normal"
              type="number"
            />
            <Controls.Input
              required
              name="Threshold_Max"
              placeholder="Threshold Max"
              value={values.Threshold_Max}
              onChange={handleInputChange}
              error={errors.Threshold_Max}
              fullWidth
              autoFocus
              margin="normal"
              type="number"
            />
            <FormControl fullWidth variant="filled" margin="normal">
              <InputLabel>Risk Level</InputLabel>
              <Select
                placeholder="Risk_Level"
                name="Risk_Level"
                value={values.Risk_Level || ""} // Bind to `values.Status`
                onChange={
                  (e) => setValues({ ...values, Risk_Level: e.target.value }) // Update status in values
                }
              >
                <MenuItem value="Low">Low</MenuItem>
                <MenuItem value="Medium">Medium</MenuItem>
                <MenuItem value="High">High</MenuItem>
              </Select>
            </FormControl>
            <Controls.Input
              required
              name="Mitigation"
              placeholder="Mitigation"
              value={values.Mitigation}
              onChange={handleInputChange}
              error={errors.Mitigation}
              fullWidth
              autoFocus
              margin="normal"
              sx={{
                height: 200, // Set the outer height
                "& .MuiInputBase-root": { height: "100%" }, // Ensure inner input spans full height
              }}
              multiline
              rows={8}
            />

            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                flexDirection: "column",
              }}
            >
              <div>
                <Controls.Button
                  type="submit"
                  text="Submit"
                  style={{ borderRadius: "8px" }}
                />
                <Controls.Button
                  text="Reset"
                  onClick={handleReset}
                  color="default"
                  style={{ borderRadius: "8px" }}
                />
              </div>
            </div>
          </Grid>
        </Grid>
      </Form>
    </AppTheme>
  );
}
