import React, { useState, useEffect, useMemo } from "react";
import Controls from "../../components/assests/Controls";
import PageHeader from "../../components/assests/PageHeader";
import {
  TableBody,
  TableRow,
  TableCell,
  Toolbar,
  InputAdornment,
  TableHead,
  TableSortLabel,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import AddIcon from "@mui/icons-material/Add";
import Popup from "../../components/assests/Popup";
import CloseIcon from "@mui/icons-material/Close";
import UseTable from "../../components/assests/UseTable";
import AppTheme from "../../Theme/AppTheme";
import KPIForm from "./KPIForm";
import axios from "axios";
import ConfirmationDialog from "../../components/assests/ConfirmationDialog";
import Notification from "../../components/assests/Notification";
import MovingIcon from "@mui/icons-material/Moving";
const proxy = process.env.REACT_APP_PROXY;

// Styled components
const ContentContainer = styled("div", {
  shouldForwardProp: (prop) => prop !== "drawerWidth", // Exclude drawerWidth prop from being passed to DOM
})(({ theme, drawerWidth }) => ({
  marginLeft: drawerWidth === 240 ? 240 : 0,
  transition: "margin-left 0.3s ease",
  width: `calc(100% - ${drawerWidth}px)`,
}));

const SearchInput = styled("div")(({ theme }) => ({
  flex: 1,
  marginRight: theme.spacing(1),
  minWidth: 0,
}));

const NewButton = styled("div")(({ theme }) => ({
  marginLeft: theme.spacing(1),
}));

const headCells = [
  { id: "KPI_Id", label: "ID" },
  { id: "KPI_Name", label: "KPI" },
  { id: "Objective_Id", label: "Objective" },
  { id: "Start_Date", label: "Start Date" },
  { id: "End_Date", label: "End Date" },
  { id: "Target_Value", label: "Target Value" },
  { id: "Actual_Value", label: "Actual Value" },
  { id: "actions", label: "Actions", disableSorting: true },
];

export default function KPI({ drawerWidth, ...props }) {
  const [KPIsRecords, setKPIsRecords] = useState([]);
  const [openPopupKPI, setOpenPopupKPI] = useState(false);
  const [recordForEdit, setRecordForEdit] = useState(null);
  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState("Strategic_Plan_ID");
  const [reloadKPI, setreloadKPI] = useState(false);
  const [openConfirmDialog, setOpenConfirmDialog] = useState(false);
  const [KPIIdToDelete, setKPIIdToDelete] = useState(null);
  const [searchInput, setSearchInput] = useState("");
  const [notify, setNotify] = useState({
    isOpen: false,
    message: "",
    type: "",
  });
  const [objectives, setObjectives] = useState([]);

  const { TblContainer } = UseTable(KPIsRecords, headCells);

  useEffect(() => {
    const fetchObjectives = async () => {
      try {
        const response = await axios.get(`${proxy}/objective/getAllObjectives`);
        setObjectives(response.data.data);
      } catch (error) {}
    };
    fetchObjectives();
  }, []);

  const fetchKPIs = async () => {
    try {
      const response = await axios.get(`${proxy}/kpi/getAllKPIs`);
      setKPIsRecords(response.data.data);
      console.log(response.data.data);
    } catch (error) {
      showNotification({
        isOpen: true,
        message: "Failed to fetch KPIs",
        type: "error",
      });
    }
  };

  const showNotification = (notification) => {
    setNotify(notification);
  };

  const getObjectivetName = (objectiveId) => {
    const objective = objectives.find(
      (objective) => objective.Strategic_Objectives_ID === objectiveId
    );
    return objective ? objective.Description : "Unknown";
  };

  useEffect(() => {
    fetchKPIs();
  }, [reloadKPI]);

  const handleRequestSort = (event, property) => {
    const isAscending = orderBy === property && order === "asc";
    const newOrder = isAscending ? "desc" : "asc";
    setOrder(newOrder);
    setOrderBy(property);
  };

  const sortedRecords = [...KPIsRecords].sort((a, b) => {
    if (a[orderBy] < b[orderBy]) {
      return order === "asc" ? -1 : 1;
    }
    if (a[orderBy] > b[orderBy]) {
      return order === "asc" ? 1 : -1;
    }
    return 0;
  });

  const openInPopup = (item) => {
    setRecordForEdit(item);
    setOpenPopupKPI(true);
  };

  const confirmDeleteKPI = (KPI_Id) => {
    setKPIIdToDelete(KPI_Id);
    setOpenConfirmDialog(true);
  };

  const handleConfirmDelete = async () => {
    setOpenConfirmDialog(false);
    if (KPIIdToDelete) {
      try {
        const response = await axios.delete(
          `${proxy}/kpi/deleteKPI/${KPIIdToDelete}`
        );
        if (response && response.data) {
          setreloadKPI((prev) => !prev);
        }
      } catch (error) {
        showNotification({
          isOpen: true,
          message: "Failed to delete KPI",
          type: "error",
        });
      } finally {
        setKPIIdToDelete(null); // Reset after delete
      }
    }
  };

  const formatDate = (dateString) => {
    if (!dateString) return "";
    const date = new Date(dateString);
    return new Intl.DateTimeFormat("en-GB", {
      day: "2-digit",
      month: "short",
      year: "numeric",
    }).format(date);
  };

  const filteredRecords = useMemo(() => {
    return sortedRecords.filter((kpi) => {
      return (
        kpi.KPI_Name &&
        kpi.KPI_Name.toLowerCase().includes(searchInput.toLowerCase())
      );
    });
  }, [sortedRecords, searchInput]);

  return (
    <AppTheme {...props}>
      <ContentContainer drawerWidth={drawerWidth}>
        <PageHeader
          title="KPI Page"
          subTitle="Define KPIs for your organization/Company"
          icon={<MovingIcon fontSize="large" />}
        />

        <Toolbar sx={{ display: "flex", alignItems: "center" }}>
          <SearchInput>
            <Controls.Input
              sx={{ width: "100%" }}
              id="search-KPI-data"
              placeholder="Search KPIs"
              value={searchInput}
              onChange={(e) => setSearchInput(e.target.value)}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <Controls.ActionButton
                      color="secondary"
                      onClick={() => setSearchInput("")}
                    >
                      <CloseIcon fontSize="small" />
                    </Controls.ActionButton>
                  </InputAdornment>
                ),
              }}
            />
          </SearchInput>
          <NewButton>
            <Controls.Button
              text="Add New KPI"
              variant="outlined"
              startIcon={<AddIcon />}
              onClick={() => {
                setRecordForEdit(null);
                setOpenPopupKPI(true);
              }}
            />
          </NewButton>
        </Toolbar>

        <TblContainer>
          <TableHead>
            <TableRow>
              {headCells.map((headCell) => (
                <TableCell
                  sx={{ bgcolor: "#f4f6fa" }}
                  key={headCell.id}
                  sortDirection={orderBy === headCell.id ? order : false}
                >
                  {headCell.disableSorting ? (
                    headCell.label
                  ) : (
                    <TableSortLabel
                      active={orderBy === headCell.id}
                      direction={orderBy === headCell.id ? order : "asc"}
                      onClick={(event) => handleRequestSort(event, headCell.id)}
                    >
                      {headCell.label}
                    </TableSortLabel>
                  )}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {filteredRecords.map((item, index) => {
              const serialNumber = index + 1; // Move this line inside the map function

              return (
                <TableRow key={item.KPI_Id}>
                  <TableCell>{serialNumber}</TableCell>
                  <TableCell>{item.KPI_Name}</TableCell>
                  <TableCell>{getObjectivetName(item.Objective_Id)}</TableCell>
                  <TableCell>{formatDate(item.Start_Date)}</TableCell>
                  <TableCell>{formatDate(item.End_Date)}</TableCell>
                  <TableCell>{item.Target_Value}</TableCell>
                  <TableCell>{item.Actual_Value}</TableCell>
                  <TableCell>
                    <Controls.ActionButton
                      color="primary"
                      onClick={() => {
                        openInPopup(item);
                      }}
                    >
                      <EditOutlinedIcon fontSize="small" />
                    </Controls.ActionButton>
                    <Controls.ActionButton
                      color="secondary"
                      onClick={() => confirmDeleteKPI(item.KPI_Id)}
                    >
                      <CloseIcon fontSize="small" />
                    </Controls.ActionButton>
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </TblContainer>
        <Popup
          title="KPI Form"
          openPopup={openPopupKPI}
          setOpenPopup={setOpenPopupKPI}
        >
          <KPIForm
            recordForEdit={recordForEdit}
            onSubmit={() => setreloadKPI((prev) => !prev)}
            setOpenPopup={setOpenPopupKPI}
            showNotification={showNotification}
          />
        </Popup>

        <ConfirmationDialog
          open={openConfirmDialog}
          setOpen={setOpenConfirmDialog}
          DialogName="Delete KPI Confirmation"
          DialogText="Are you sure you want to delete this KPI?"
          onConfirm={() => {
            handleConfirmDelete();
            showNotification({
              isOpen: true,
              message: "KPI deleted successfully",
              type: "success",
            });
          }}
          onClose={() => setOpenConfirmDialog(false)}
        />
        <Notification notify={notify} setNotify={setNotify} />
      </ContentContainer>
    </AppTheme>
  );
}
