import React, { useState, useEffect } from "react";
import {
  createBrowserRouter,
  Outlet,
  RouterProvider,
  Navigate,
} from "react-router-dom";
import SignIn from "./pages/signin/SignIn";
import Dahsboard from "./pages/Dashboard/Dahsboard";
import SideMenu from "./components/SideMenu/SideMenu";
import KPI from "./pages/KPI/KPI";
import KRI from "./pages/KRI/KRI";
import StrategicPlan from "./pages/StrategicPlan/StrategicPlan";
import Objectives from "./pages/Objectives/Objectives";
import Initiatives from "./pages/initiatives/Initiatives";
import User from "./pages/userDepartment/User";
import { styled } from "@mui/material/styles";
import { UserProvider, useUser } from "./utils/UserContext";
import ResetPassword from "./pages/signin/ResetPassword";
import { useMediaQuery } from "@mui/material";
import CssBaseline from "@mui/material/CssBaseline";
import { DrawerProvider, useDrawer } from "./utils/DrawerProvider";
import { ThemeProvider, createTheme } from "@mui/material/styles"; // Import ThemeProvider
const theme = createTheme();

const Layout = () => {
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  const [drawerWidth, setDrawerWidth] = useState(() => {
    const savedWidth = localStorage.getItem("drawerWidth");
    return savedWidth !== null ? Number(savedWidth) : isMobile ? 0 : 230;
  });

  const { drawerWidth: contextDrawerWidth } = useDrawer(); // Get drawer width from context

  // Dynamic marginLeft calculation based on drawerWidth state
  const MainContent = styled("div")(({ theme, drawerWidth }) => ({
    marginLeft: drawerWidth, // Use the drawerWidth directly from state
    padding: theme.spacing(2),
    transition: "margin 0.3s ease-in-out",
    backgroundColor: "#F5F8FB",
    minHeight: "100vh",
    boxSizing: "border-box",
  }));

  return (
    <MainContent drawerWidth={contextDrawerWidth}>
      <SideMenu defaultDrawerWidth={contextDrawerWidth} />
      <Outlet />
    </MainContent>
  );
};

// Protected Route Component
const ProtectedRoute = ({ children }) => {
  const { user, loading } = useUser();

  if (loading) return <p>Loading...</p>; // Show loading state until session is verified

  if (!loading && !user) return <Navigate to="/login" replace />;
  return children;
};

// Router Configuration
const router = createBrowserRouter([
  {
    path: "/",
    element: (
      <ProtectedRoute>
        <Layout />
      </ProtectedRoute>
    ),
    children: [
      { path: "/dashboard", element: <Dahsboard /> },
      { path: "/kpi", element: <KPI /> },
      { path: "/kri", element: <KRI /> },
      { path: "/strategicPlan", element: <StrategicPlan /> },
      { path: "/objectives", element: <Objectives /> },
      { path: "/initiatives", element: <Initiatives /> },
      { path: "/users", element: <User /> },
      { path: "/", element: <Navigate to="/login" replace /> },
    ],
  },
  { path: "/login", element: <SignIn /> },
  { path: "/reset-password", element: <ResetPassword /> },
  { path: "*", element: <Navigate to="/login" replace /> },
]);

// App Component
function App() {
  return (
    <ThemeProvider theme={theme}>
      <UserProvider>
        <DrawerProvider>
          <CssBaseline enableColorScheme />
          <InnerApp />
        </DrawerProvider>
      </UserProvider>
    </ThemeProvider>
  );
}

const InnerApp = () => {
  return (
    <div className="app">
      <div className="container">
        <RouterProvider router={router} />
      </div>
    </div>
  );
};

export default App;
