import React, { useState, useEffect, useMemo } from "react";
import Controls from "../../components/assests/Controls";
import PageHeader from "../../components/assests/PageHeader";
import {
  TableBody,
  TableRow,
  TableCell,
  Toolbar,
  InputAdornment,
  TableHead,
  TableSortLabel,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import AddIcon from "@mui/icons-material/Add";
import Popup from "../../components/assests/Popup";
import CloseIcon from "@mui/icons-material/Close";
import UseTable from "../../components/assests/UseTable";
import AppTheme from "../../Theme/AppTheme";
import KRIForm from "./KRIForm";
import ConfirmationDialog from "../../components/assests/ConfirmationDialog";
import Notification from "../../components/assests/Notification";
import GppMaybeIcon from "@mui/icons-material/GppMaybe";
import axios from "axios";
const proxy = process.env.REACT_APP_PROXY;

const ContentContainer = styled("div", {
  shouldForwardProp: (prop) => prop !== "drawerWidth", // Exclude drawerWidth prop from being passed to DOM
})(({ theme, drawerWidth }) => ({
  marginLeft: drawerWidth === 240 ? 240 : 0,
  transition: "margin-left 0.3s ease",
  width: `calc(100% - ${drawerWidth}px)`,
}));

const SearchInput = styled("div")(({ theme }) => ({
  flex: 1,
  marginRight: theme.spacing(1),
  minWidth: 0,
}));

const NewButton = styled("div")(({ theme }) => ({
  marginLeft: theme.spacing(1),
}));

const headCells = [
  { id: "KRI_Id", label: "ID" },
  { id: "KRI_Name", label: "KRI" },
  { id: "Objective_Id", label: "Objective" },
  { id: "Threshold_Min", label: "Threshold Min" },
  { id: "Threshold_Max", label: "Threshold Max" },
  { id: "Mitigation", label: "Mitigation" },
  { id: "Risk_Level", label: "Risk Level" },
  { id: "actions", label: "Actions", disableSorting: true },
];

export default function KRI({ drawerWidth, ...props }) {
  const [KRIsRecords, setKRIsRecords] = useState([]);
  const [openPopupKRI, setOpenPopupKRI] = useState(false);
  const [recordForEdit, setRecordForEdit] = useState(null);
  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState("Strategic_Plan_ID");
  const [reloadKRI, setReloadKRI] = useState(false);
  const [openConfirmDialog, setOpenConfirmDialog] = useState(false);
  const [KRIIdToDelete, setKRIIdToDelete] = useState(null);
  const [searchInput, setSearchInput] = useState("");
  const [notify, setNotify] = useState({
    isOpen: false,
    message: "",
    type: "",
  });
  const [objectives, setObjectives] = useState([]);

  const { TblContainer } = UseTable(KRIsRecords, headCells);

  useEffect(() => {
    const fetchObjectives = async () => {
      try {
        const response = await axios.get(`${proxy}/objective/getAllObjectives`);
        setObjectives(response.data.data);
      } catch (error) {}
    };
    fetchObjectives();
  }, []);

  const fetchKPIs = async () => {
    try {
      const response = await axios.get(`${proxy}/kri/getAllKRIs`);
      setKRIsRecords(response.data.data);
      console.log(response.data.data);
    } catch (error) {
      showNotification({
        isOpen: true,
        message: "Failed to fetch KRIs",
        type: "error",
      });
    }
  };

  const showNotification = (notification) => {
    setNotify(notification);
  };

  const getObjectivetName = (objectiveId) => {
    const objective = objectives.find(
      (objective) => objective.Strategic_Objectives_ID === objectiveId
    );
    return objective ? objective.Description : "Unknown";
  };

  useEffect(() => {
    fetchKPIs();
  }, [reloadKRI]);

  const handleRequestSort = (event, property) => {
    const isAscending = orderBy === property && order === "asc";
    const newOrder = isAscending ? "desc" : "asc";
    setOrder(newOrder);
    setOrderBy(property);
  };

  const sortedRecords = [...KRIsRecords].sort((a, b) => {
    if (a[orderBy] < b[orderBy]) {
      return order === "asc" ? -1 : 1;
    }
    if (a[orderBy] > b[orderBy]) {
      return order === "asc" ? 1 : -1;
    }
    return 0;
  });

  const openInPopup = (item) => {
    setRecordForEdit(item);
    setOpenPopupKRI(true);
  };

  const confirmDeleteKRI = (KRI_Id) => {
    setKRIIdToDelete(KRI_Id);
    setOpenConfirmDialog(true);
  };

  const handleConfirmDelete = async () => {
    setOpenConfirmDialog(false);
    if (KRIIdToDelete) {
      try {
        const response = await axios.delete(
          `${proxy}/kri/deleteKRI/${KRIIdToDelete}`
        );
        if (response && response.data) {
          setReloadKRI((prev) => !prev);
        }
      } catch (error) {
        showNotification({
          isOpen: true,
          message: "Failed to delete KRI",
          type: "error",
        });
      } finally {
        setKRIIdToDelete(null); // Reset after delete
      }
    }
  };

  const filteredRecords = useMemo(() => {
    return sortedRecords.filter((kpi) => {
      return (
        (kpi.KRI_Name &&
          kpi.KRI_Name.toLowerCase().includes(searchInput.toLowerCase())) ||
        (kpi.Risk_Level &&
          kpi.Risk_Level.toLowerCase().includes(searchInput.toLowerCase())) ||
        (kpi.Mitigation &&
          kpi.Mitigation.toLowerCase().includes(searchInput.toLowerCase()))
      );
    });
  }, [sortedRecords, searchInput]);

  return (
    <AppTheme {...props}>
      <ContentContainer drawerWidth={drawerWidth}>
        <PageHeader
          title="KRI Page"
          subTitle="Define KRIs for your organization/Company"
          icon={<GppMaybeIcon fontSize="large" />}
        />
        <Toolbar sx={{ display: "flex", alignItems: "center" }}>
          <SearchInput>
            <Controls.Input
              sx={{ width: "100%" }}
              id="search-KRI-data"
              placeholder="Search KRIs"
              value={searchInput}
              onChange={(e) => setSearchInput(e.target.value)}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <Controls.ActionButton
                      color="secondary"
                      onClick={() => setSearchInput("")}
                    >
                      <CloseIcon fontSize="small" />
                    </Controls.ActionButton>
                  </InputAdornment>
                ),
              }}
            />
          </SearchInput>
          <NewButton>
            <Controls.Button
              text="Add New KRI"
              variant="outlined"
              startIcon={<AddIcon />}
              onClick={() => {
                setRecordForEdit(null);
                setOpenPopupKRI(true);
              }}
            />
          </NewButton>
        </Toolbar>

        <TblContainer>
          <TableHead>
            <TableRow>
              {headCells.map((headCell) => (
                <TableCell
                  sx={{ bgcolor: "#f4f6fa" }}
                  key={headCell.id}
                  sortDirection={orderBy === headCell.id ? order : false}
                >
                  {headCell.disableSorting ? (
                    headCell.label
                  ) : (
                    <TableSortLabel
                      active={orderBy === headCell.id}
                      direction={orderBy === headCell.id ? order : "asc"}
                      onClick={(event) => handleRequestSort(event, headCell.id)}
                    >
                      {headCell.label}
                    </TableSortLabel>
                  )}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {filteredRecords.map((item, index) => {
              const serialNumber = index + 1;

              // Determine the background color based on Risk_Level
              let backgroundColor;
              let fontColor = "#FAF9F6"; // default font color

              if (item.Risk_Level === "Low") {
                backgroundColor = "#2E7D32"; // Green
              } else if (item.Risk_Level === "High") {
                backgroundColor = "#D32F2F"; // Red
              } else {
                backgroundColor = "#0948B3"; // Blue
              }

              return (
                <TableRow key={item.KRI_Id}>
                  <TableCell>{serialNumber}</TableCell>
                  <TableCell>{item.KRI_Name}</TableCell>
                  <TableCell>{getObjectivetName(item.Objective_Id)}</TableCell>
                  <TableCell>{item.Threshold_Min}</TableCell>
                  <TableCell>{item.Threshold_Max}</TableCell>
                  <TableCell>{item.Mitigation}</TableCell>
                  <TableCell>
                    <TableCell
                      sx={{
                        backgroundColor: backgroundColor,
                        borderRadius: "8px", // Rounded corners
                        color: fontColor, // White font color
                        textAlign: "center", // Center the text
                        height: "10px", // Adjusted height with unit (e.g., "px")
                        display: "flex", // Ensure content is vertically centered
                        alignItems: "center", // Vertically center the text
                        justifyContent: "center", // Horizontally center the text
                        width: "80px",
                      }}
                    >
                      {item.Risk_Level}
                    </TableCell>
                  </TableCell>
                  <TableCell>
                    <Controls.ActionButton
                      color="primary"
                      onClick={() => openInPopup(item)}
                    >
                      <EditOutlinedIcon fontSize="small" />
                    </Controls.ActionButton>
                    <Controls.ActionButton
                      color="secondary"
                      onClick={() => confirmDeleteKRI(item.KRI_Id)}
                    >
                      <CloseIcon fontSize="small" />
                    </Controls.ActionButton>
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </TblContainer>
        <Popup
          title="KRI Form"
          openPopup={openPopupKRI}
          setOpenPopup={setOpenPopupKRI}
        >
          <KRIForm
            recordForEdit={recordForEdit}
            onSubmit={() => setReloadKRI((prev) => !prev)}
            setOpenPopup={setOpenPopupKRI}
            showNotification={showNotification}
          />
        </Popup>

        <ConfirmationDialog
          open={openConfirmDialog}
          setOpen={setOpenConfirmDialog}
          DialogName="Delete KRI Confirmation"
          DialogText="Are you sure you want to delete this KRI?"
          onConfirm={() => {
            handleConfirmDelete();
            showNotification({
              isOpen: true,
              message: "KRI deleted successfully",
              type: "success",
            });
          }}
          onClose={() => setOpenConfirmDialog(false)}
        />
        <Notification notify={notify} setNotify={setNotify} />
      </ContentContainer>
    </AppTheme>
  );
}
