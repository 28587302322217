import * as React from "react";
import CssBaseline from "@mui/material/CssBaseline";
import Box from "@mui/material/Box";
import AppTheme from "../../Theme/AppTheme";
import MovingIcon from "@mui/icons-material/Moving";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import EqualizerIcon from "@mui/icons-material/Equalizer";
import {
  chartsCustomizations,
  dataGridCustomizations,
  datePickersCustomizations,
  treeViewCustomizations,
} from "./theme/customizations";
import General from "./General";
import KPI from "./KPI";
import Initiative from "./Initiative";
import KRI from "./KRI";
import DownloadDoneIcon from "@mui/icons-material/DownloadDone";
import GppMaybeIcon from "@mui/icons-material/GppMaybe";

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
  };
}

const xThemeComponents = {
  ...chartsCustomizations,
  ...dataGridCustomizations,
  ...datePickersCustomizations,
  ...treeViewCustomizations,
};

export default function Dashboard(props) {
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  return (
    <AppTheme {...props} themeComponents={xThemeComponents}>
      <CssBaseline enableColorScheme />
      <Box
        sx={{
          width: "100%",
          bgcolor: "background.paper",
          color: "#2E3650",
        }}
      >
        <Tabs
          value={value}
          onChange={handleChange}
          textColor="secondary"
          indicatorColor="secondary"
          aria-label="secondary tabs example"
          centered
          variant="fullWidth"
        >
          <Tab
            icon={<EqualizerIcon />}
            iconPosition="start"
            label="Main Dashboard"
            {...a11yProps(0)}
          />
          <Tab
            icon={<MovingIcon />}
            iconPosition="start"
            label="KPI"
            {...a11yProps(1)}
          />
          <Tab
            icon={<DownloadDoneIcon />}
            iconPosition="start"
            label="Initiative"
            {...a11yProps(2)}
          />
          <Tab
            icon={<GppMaybeIcon />}
            iconPosition="start"
            label="KRI"
            {...a11yProps(3)}
          />
        </Tabs>
      </Box>
      <CustomTabPanel value={value} index={0}>
        <General />
      </CustomTabPanel>
      <CustomTabPanel value={value} index={1}>
        <KPI />
      </CustomTabPanel>
      <CustomTabPanel value={value} index={2}>
        <Initiative />
      </CustomTabPanel>
      <CustomTabPanel value={value} index={3}>
        <KRI />
      </CustomTabPanel>
    </AppTheme>
  );
}
