import React, { createContext, useContext, useState, useEffect } from "react";
import { getSession } from "./auth";

const UserContext = createContext();
export const UserProvider = ({ children }) => {
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    console.log("Running useEffect in UserProvider...");
    const storedUser = JSON.parse(localStorage.getItem("user"));
    const token = document.cookie
      .split("; ")
      .find((row) => row.startsWith("access_token="))
      ?.split("=")[1];

    console.log("token", token);

    // If user data is found in localStorage AND token exists, set user state
    if (storedUser && token) {
      setUser(storedUser);
      setLoading(false);
    } else {
      // Fetch session from the server if no user data in localStorage or token is missing
      const fetchSession = async () => {
        try {
          const session = await getSession();
          if (session && session.user) {
            setUser(session.user);
            localStorage.setItem("user", JSON.stringify(session.user)); // Store user in localStorage
            document.cookie = `access_token=${
              session.token
            }; max-age=${31536000}; path=/`; // Set token in cookies
          }
        } catch (error) {
          setUser(null); // In case of failure, set user as null
        } finally {
          setLoading(false);
        }
      };
      fetchSession();
    }
  }, []);

  // Update the user in localStorage when it changes
  useEffect(() => {
    if (user) {
      localStorage.setItem("user", JSON.stringify(user)); // Persist user in localStorage
    } else {
      localStorage.removeItem("user"); // Clear user from localStorage if logged out
    }
  }, [user]);

  return (
    <UserContext.Provider value={{ user, setUser, loading }}>
      {children}
    </UserContext.Provider>
  );
};

export const useUser = () => {
  const context = useContext(UserContext);
  return context;
};
