import React, { createContext, useState, useContext, useEffect } from "react";

// Create context to hold drawer state
const DrawerContext = createContext();

export const useDrawer = () => useContext(DrawerContext);

export const DrawerProvider = ({ children }) => {
  const [drawerWidth, setDrawerWidth] = useState(() => {
    const savedWidth = localStorage.getItem("drawerWidth");
    return savedWidth ? Number(savedWidth) : 230; // Default to 230 if not saved
  });

  const toggleDrawer = () => {
    const newWidth = drawerWidth === 230 ? 80 : 230;
    setDrawerWidth(newWidth);
    localStorage.setItem("drawerWidth", newWidth); // Save to localStorage
  };

  useEffect(() => {
    const savedWidth = localStorage.getItem("drawerWidth");
    if (savedWidth) {
      setDrawerWidth(Number(savedWidth));
    }
  }, []);

  return (
    <DrawerContext.Provider value={{ drawerWidth, toggleDrawer }}>
      {children}
    </DrawerContext.Provider>
  );
};
