import React, { useState, useEffect } from "react";
import Controls from "../../components/assests/Controls";
import PageHeader from "../../components/assests/PageHeader";
import {
  Paper,
  TableBody,
  TableRow,
  TableCell,
  Toolbar,
  InputAdornment,
  TableHead,
  TableSortLabel,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import AddIcon from "@mui/icons-material/Add";
import Popup from "../../components/assests/Popup";
import CloseIcon from "@mui/icons-material/Close";
import GroupIcon from "@mui/icons-material/Group";
import UseTable from "../../components/assests/UseTable";
import AppTheme from "../../Theme/AppTheme";
import UserForm from "./UserForm";
import DepartmentForm from "./DepartmentForm";
import axios from "axios";
import ConfirmationDialog from "../../components/assests/ConfirmationDialog";
import Notification from "../../components/assests/Notification";

const proxy = process.env.REACT_APP_PROXY;

// Styled components
const ContentContainer = styled("div", {
  shouldForwardProp: (prop) => prop !== "drawerWidth", // Exclude drawerWidth prop from being passed to DOM
})(({ theme, drawerWidth }) => ({
  marginLeft: drawerWidth === 240 ? 240 : 0,
  transition: "margin-left 0.3s ease",
  width: `calc(100% - ${drawerWidth}px)`,
}));

const SearchInput = styled("div")(({ theme }) => ({
  flex: 1,
  marginRight: theme.spacing(1),
  minWidth: 0,
}));

const NewButton = styled("div")(({ theme }) => ({
  marginLeft: theme.spacing(1),
}));

const headCells = [
  { id: "userID", label: "ID" },
  { id: "name", label: "Name" },
  { id: "email", label: "Email" },
  { id: "department", label: "Department" },
  { id: "role", label: "Role" },
  { id: "actions", label: "Actions", disableSorting: true },
];

function User(props) {
  const [usersRecords, setUsersRecords] = useState([]);
  const [openPopupUser, setOpenPopupUser] = useState(false);
  const [openPopupDep, setOpenPopupDep] = useState(false);
  const [recordForEdit, setRecordForEdit] = useState(null);
  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState("userID");
  const [departments, setDepartments] = useState([]);
  const [roles, setRoles] = useState([]);
  const [reloadUsers, setReloadUsers] = useState(false);
  const [openConfirmDialog, setOpenConfirmDialog] = useState(false);
  const [userIdToDelete, setUserIdToDelete] = useState(null);
  const [searchInput, setSearchInput] = useState("");
  const [notify, setNotify] = useState({
    isOpen: false,
    message: "",
    type: "",
  });

  const { TblContainer } = UseTable(usersRecords, headCells);

  useEffect(() => {
    const fetchDepartments = async () => {
      try {
        const response = await axios.get(`${proxy}/department/getDep`);
        setDepartments(response.data);
      } catch (error) {}
    };

    fetchDepartments();
  }, []);

  useEffect(() => {
    const fetchRoles = async () => {
      try {
        const response = await axios.get(`${proxy}/users/getroles`);
        setRoles(response.data);
      } catch (error) {}
    };
    fetchRoles();
  }, []);

  const fetchUsers = async () => {
    try {
      const response = await axios.get(`${proxy}/users/getuser`);
      setUsersRecords(response.data);
    } catch (error) {}
  };

  const showNotification = (notification) => {
    setNotify(notification);
  };

  useEffect(() => {
    fetchUsers();
  }, [reloadUsers]);

  const getDepartmentName = (departmentId) => {
    const department = departments.find(
      (dep) => dep.Department_ID === departmentId
    );
    return department ? department.Department_Name : "Unknown";
  };

  const getRoleName = (roleId) => {
    const role = roles.find((role) => role.Role_ID === roleId);
    return role ? role.Role_Name : "Unknown";
  };

  const handleRequestSort = (event, property) => {
    const isAscending = orderBy === property && order === "asc";
    const newOrder = isAscending ? "desc" : "asc";
    setOrder(newOrder);
    setOrderBy(property);
  };

  const sortedRecords = [...usersRecords].sort((a, b) => {
    if (a[orderBy] < b[orderBy]) {
      return order === "asc" ? -1 : 1;
    }
    if (a[orderBy] > b[orderBy]) {
      return order === "asc" ? 1 : -1;
    }
    return 0;
  });

  const openInPopup = (item) => {
    setRecordForEdit(item);
    setOpenPopupUser(true);
  };

  const confirmDeleteUser = (userID) => {
    setUserIdToDelete(userID);
    setOpenConfirmDialog(true);
  };

  const handleConfirmDelete = async () => {
    setOpenConfirmDialog(false);
    if (userIdToDelete) {
      try {
        const response = await axios.delete(
          `${proxy}/users/deleteUser/${userIdToDelete}`
        );
        if (response && response.data) {
          setReloadUsers((prev) => !prev);
        } else {
        }
      } catch (error) {}
    }
  };

  const filteredRecords = sortedRecords.filter((user) => {
    const departmentName = getDepartmentName(user.department);
    const roleName = getRoleName(user.role);
    return (
      (user.name &&
        user.name.toLowerCase().includes(searchInput.toLowerCase())) ||
      (user.email &&
        user.email.toLowerCase().includes(searchInput.toLowerCase())) ||
      (departmentName &&
        departmentName.toLowerCase().includes(searchInput.toLowerCase())) ||
      (roleName && user.role.toLowerCase().includes(searchInput.toLowerCase()))
    );
  });

  return (
    <AppTheme {...props}>
      <ContentContainer>
        <PageHeader
          title="Users Page"
          subTitle="Define Users, Roles, and Departments"
          icon={<GroupIcon fontSize="large" />}
        />
        <Toolbar sx={{ display: "flex", alignItems: "center" }}>
          <SearchInput>
            <Controls.Input
              sx={{ width: "100%" }}
              id="search-users-data"
              placeholder="Search Users"
              value={searchInput} // bind value to searchInput state
              onChange={(e) => setSearchInput(e.target.value)}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <Controls.ActionButton
                      color="secondary"
                      onClick={() => setSearchInput("")}
                    >
                      <CloseIcon fontSize="small" />
                    </Controls.ActionButton>
                  </InputAdornment>
                ),
              }}
            />
          </SearchInput>
          <NewButton>
            <Controls.Button
              text="Add New User"
              variant="outlined"
              startIcon={<AddIcon />}
              onClick={() => {
                setRecordForEdit(null);
                setOpenPopupUser(true);
              }}
            />
            <Controls.Button
              text="Add New Department"
              variant="outlined"
              startIcon={<AddIcon />}
              onClick={() => {
                setRecordForEdit(null);
                setOpenPopupDep(true);
              }}
            />
          </NewButton>
        </Toolbar>

        <TblContainer>
          <TableHead>
            <TableRow>
              {headCells.map((headCell) => (
                <TableCell
                  sx={{ bgcolor: "#f4f6fa" }}
                  key={headCell.id}
                  sortDirection={orderBy === headCell.id ? order : false}
                >
                  {headCell.disableSorting ? (
                    headCell.label
                  ) : (
                    <TableSortLabel
                      active={orderBy === headCell.id}
                      direction={orderBy === headCell.id ? order : "asc"}
                      onClick={(event) => handleRequestSort(event, headCell.id)}
                    >
                      {headCell.label}
                    </TableSortLabel>
                  )}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {filteredRecords.map((item, index) => {
              const serialNumber = index + 1;

              return (
                <TableRow key={item.userID}>
                  <TableCell>{serialNumber}</TableCell>
                  <TableCell>{item.name}</TableCell>
                  <TableCell>{item.email}</TableCell>
                  <TableCell>{getDepartmentName(item.department)}</TableCell>
                  <TableCell>{getRoleName(item.Role_ID)}</TableCell>
                  <TableCell>
                    <Controls.ActionButton
                      color="primary"
                      onClick={() => openInPopup(item)}
                    >
                      <EditOutlinedIcon fontSize="small" />
                    </Controls.ActionButton>
                    <Controls.ActionButton
                      color="secondary"
                      onClick={() => confirmDeleteUser(item.userID)}
                    >
                      <CloseIcon fontSize="small" />
                    </Controls.ActionButton>
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </TblContainer>

        <Popup
          title="User Form"
          openPopup={openPopupUser}
          setOpenPopup={setOpenPopupUser}
        >
          <UserForm
            recordForEdit={recordForEdit}
            onSubmit={() => setReloadUsers((prev) => !prev)}
            setOpenPopup={setOpenPopupUser} // Pass the setter directly
            departments={departments}
            showNotification={showNotification}
          />
        </Popup>

        <Popup
          title="Department Form"
          openPopup={openPopupDep}
          setOpenPopup={setOpenPopupDep}
        >
          <DepartmentForm
            onSubmit={() => {
              setOpenPopupDep(false); // Close popup upon successful form submission
              setReloadUsers((prev) => !prev); // Reload department list if needed
            }}
            setOpenPopup={setOpenPopupDep}
            setReloadUsers={setReloadUsers}
            showNotification={showNotification}
          />
        </Popup>

        <ConfirmationDialog
          open={openConfirmDialog}
          setOpen={setOpenConfirmDialog}
          DialogName="Delete User Confirmation"
          DialogText="Are you sure you want to delete this User?"
          onConfirm={() => {
            handleConfirmDelete();
            showNotification({
              isOpen: true,
              message: "User deleted successfully",
              type: "success",
            });
          }}
          onClose={() => setOpenConfirmDialog(false)}
        />
        <Notification notify={notify} setNotify={setNotify} />
      </ContentContainer>
    </AppTheme>
  );
}

export default User;
