import axios from "axios";
const proxy = process.env.REACT_APP_PROXY;

export const getSession = async () => {
  try {
    const response = await axios.get(`${proxy}/auth/getSession`, {
      withCredentials: true, // Ensure cookies are sent with the request
    });
    return response.data; // Return session data (user and token)
  } catch (error) {
    console.error("Error fetching session:", error);
    throw error;
  }
};
