import React, { useEffect, useState } from "react";
import Grid from "@mui/material/Grid2";
import Controls from "../../components/assests/Controls";
import { UseForm, Form } from "../../components/assests/UseForm";
import AppTheme from "../../Theme/AppTheme";
import axios from "axios";

const proxy = process.env.REACT_APP_PROXY;

const departmentFValues = {
  departmentId: 0,
  departmentName: "",
};

export default function DepartmentForm(props) {
  const { recordForEditDepartment, onSubmit, showNotification, setClosed } =
    props; // Include onSubmit here
  const [inputs, setInputs] = useState({
    departmentName: "",
  });

  const { values, setValues, errors, handleInputChange, resetForm } = UseForm(
    recordForEditDepartment || departmentFValues,
    true
  );

  useEffect(() => {
    if (
      recordForEditDepartment &&
      recordForEditDepartment.userId !== undefined
    ) {
      setValues((prevValues) => ({
        ...prevValues,
        ...recordForEditDepartment,
      }));
    } else {
      setValues(departmentFValues);
    }
  }, [recordForEditDepartment, setValues]);

  const handleSubmit = async (event) => {
    event.preventDefault();
    const departmentData = {
      departmentName: values.departmentName,
    };

    try {
      const res = await axios.post(
        `${proxy}/department/addDep`,
        departmentData
      );
      console.log("Response:", res.status);
      showNotification({
        isOpen: true,
        message: "Department added successfully!",
        type: "success",
      });
      onSubmit();
      setClosed(false);
    } catch (error) {
      console.log(error);
    }
  };

  const handleReset = () => {
    resetForm();
    setValues(departmentFValues);
  };

  const handleInputUpdate = (e) => {
    handleInputChange(e);
    setInputs((prevInputs) => ({
      ...prevInputs,
      [e.target.name]: e.target.value,
    }));
  };

  return (
    <AppTheme>
      <Form onSubmit={handleSubmit}>
        <Grid container>
          <Grid item xs={12}>
            <Controls.Input
              required
              name="departmentName"
              placeholder="Department Name"
              value={values.departmentName}
              onChange={handleInputUpdate}
              error={errors.departmentName}
              fullWidth
              autoFocus
              sx={{ marginBottom: "1rem" }}
            />

            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                flexDirection: "column",
              }}
            >
              <div>
                <Controls.Button
                  type="submit"
                  text="Submit"
                  style={{ borderRadius: "8px" }}
                />
                <Controls.Button
                  text="Reset"
                  onClick={handleReset}
                  color="default"
                  style={{ borderRadius: "8px" }}
                />
              </div>
            </div>
          </Grid>
        </Grid>
      </Form>
    </AppTheme>
  );
}
