import React, { useState, useEffect, useMemo } from "react";
import Controls from "../../components/assests/Controls";
import PageHeader from "../../components/assests/PageHeader";
import {
  TableBody,
  TableRow,
  TableCell,
  Toolbar,
  InputAdornment,
  TableHead,
  TableSortLabel,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import AddIcon from "@mui/icons-material/Add";
import Popup from "../../components/assests/Popup";
import CloseIcon from "@mui/icons-material/Close";
import UseTable from "../../components/assests/UseTable";
import AppTheme from "../../Theme/AppTheme";
import ObjectiveForm from "./ObjectiveForm";
import axios from "axios";
import ConfirmationDialog from "../../components/assests/ConfirmationDialog";
import Notification from "../../components/assests/Notification";
import EmojiObjectsIcon from "@mui/icons-material/EmojiObjects";

const proxy = process.env.REACT_APP_PROXY;

// Styled components
const ContentContainer = styled("div", {
  shouldForwardProp: (prop) => prop !== "drawerWidth", // Exclude drawerWidth prop from being passed to DOM
})(({ theme, drawerWidth }) => ({
  marginLeft: drawerWidth === 240 ? 240 : 0,
  transition: "margin-left 0.3s ease",
  width: `calc(100% - ${drawerWidth}px)`,
}));

const SearchInput = styled("div")(({ theme }) => ({
  flex: 1,
  marginRight: theme.spacing(1),
  minWidth: 0,
}));

const NewButton = styled("div")(({ theme }) => ({
  marginLeft: theme.spacing(1),
}));

const headCells = [
  { id: "Strategic_Objectives_ID", label: "ID" },
  { id: "Strategic_Plan_ID", label: "Strategic Plan" },
  { id: "Description", label: "Objectives" },
  { id: "actions", label: "Actions", disableSorting: true },
];

export default function Objectives({ drawerWidth, ...props }) {
  const [objectivesRecords, setObjectivesRecords] = useState([]);
  const [openPopupObjective, setOpenPopupObjective] = useState(false);
  const [recordForEdit, setRecordForEdit] = useState(null);
  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState("Strategic_Objectives_ID");
  const [reloadObjective, setReloadObjective] = useState(false);
  const [openConfirmDialog, setOpenConfirmDialog] = useState(false);
  const [objectiveIdToDelete, setObjectiveIdToDelete] = useState(null);
  const [searchInput, setSearchInput] = useState("");
  const [plans, setPlans] = useState([]);

  const [notify, setNotify] = useState({
    isOpen: false,
    message: "",
    type: "",
  });

  useEffect(() => {
    const fetchplans = async () => {
      try {
        const response = await axios.get(`${proxy}/plan/getAllPlans`);
        setPlans(response.data.data);
      } catch (error) {}
    };
    fetchplans();
  }, []);

  const { TblContainer } = UseTable(objectivesRecords, headCells);

  const fetchObjectives = async () => {
    try {
      const response = await axios.get(`${proxy}/objective/getAllObjectives`);
      setObjectivesRecords(response.data.data);
    } catch (error) {
      showNotification({
        isOpen: true,
        message: "Failed to fetch plans",
        type: "error",
      });
    }
  };

  const showNotification = (notification) => {
    setNotify(notification);
  };

  const getPlantName = (PlanId) => {
    const plan = plans.find((plan) => plan.Strategic_Plan_ID === PlanId);
    return plan ? plan.strategic_plan_Description : "Unknown";
  };

  useEffect(() => {
    fetchObjectives();
  }, [reloadObjective]);

  const handleRequestSort = (event, property) => {
    const isAscending = orderBy === property && order === "asc";
    const newOrder = isAscending ? "desc" : "asc";
    setOrder(newOrder);
    setOrderBy(property);
  };

  const sortedRecords = [...objectivesRecords].sort((a, b) => {
    if (a[orderBy] < b[orderBy]) {
      return order === "asc" ? -1 : 1;
    }
    if (a[orderBy] > b[orderBy]) {
      return order === "asc" ? 1 : -1;
    }
    return 0;
  });

  const openInPopup = (item) => {
    setRecordForEdit(item);
    setOpenPopupObjective(true);
  };

  const confirmDeleteObjective = (Strategic_Objectives_ID) => {
    setObjectiveIdToDelete(Strategic_Objectives_ID);
    setOpenConfirmDialog(true);
  };

  const handleConfirmDelete = async () => {
    setOpenConfirmDialog(false);
    if (objectiveIdToDelete) {
      try {
        const response = await axios.delete(
          `${proxy}/objective/deleteObjective/${objectiveIdToDelete}`
        );
        if (response && response.data) {
          setReloadObjective((prev) => !prev);
        }
      } catch (error) {
        showNotification({
          isOpen: true,
          message: "Failed to delete plan",
          type: "error",
        });
      } finally {
        setObjectiveIdToDelete(null); // Reset after delete
      }
    }
  };

  const filteredRecords = useMemo(() => {
    return sortedRecords.filter((objective) => {
      return (
        objective.Description &&
        objective.Description.toLowerCase().includes(searchInput.toLowerCase())
      );
    });
  }, [sortedRecords, searchInput]);

  return (
    <AppTheme {...props}>
      <ContentContainer drawerWidth={drawerWidth}>
        <PageHeader
          title="Objectives Page"
          subTitle="Define Startegic Objectives"
          icon={<EmojiObjectsIcon fontSize="large" />}
        />
        <Toolbar sx={{ display: "flex", alignItems: "center" }}>
          <SearchInput>
            <Controls.Input
              sx={{ width: "100%" }}
              id="search-objective-data"
              placeholder="Search Objectives"
              value={searchInput}
              onChange={(e) => setSearchInput(e.target.value)}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <Controls.ActionButton
                      color="secondary"
                      onClick={() => setSearchInput("")}
                    >
                      <CloseIcon fontSize="small" />
                    </Controls.ActionButton>
                  </InputAdornment>
                ),
              }}
            />
          </SearchInput>
          <NewButton>
            <Controls.Button
              text="Add New Objective"
              variant="outlined"
              startIcon={<AddIcon />}
              onClick={() => {
                setRecordForEdit(null);
                setOpenPopupObjective(true);
              }}
            />
          </NewButton>
        </Toolbar>

        <TblContainer>
          <TableHead sx={{ alignContent: "center", alignItems: "center" }}>
            <TableRow sx={{ alignContent: "center", alignItems: "center" }}>
              {headCells.map((headCell) => (
                <TableCell
                  sx={{
                    bgcolor: "#f4f6fa",
                  }}
                  key={headCell.id}
                  sortDirection={orderBy === headCell.id ? order : false}
                >
                  {headCell.disableSorting ? (
                    headCell.label
                  ) : (
                    <TableSortLabel
                      active={orderBy === headCell.id}
                      direction={orderBy === headCell.id ? order : "asc"}
                      onClick={(event) => handleRequestSort(event, headCell.id)}
                    >
                      {headCell.label}
                    </TableSortLabel>
                  )}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {filteredRecords.map((item, index) => {
              const serialNumber = index + 1;

              return (
                <TableRow key={item.Strategic_Objectives_ID}>
                  <TableCell sx={{ minWidth: "15%" }}>{serialNumber}</TableCell>
                  <TableCell sx={{ width: "35%" }}>
                    {getPlantName(item.Strategic_Plan_ID)}
                  </TableCell>
                  <TableCell sx={{ width: "35%" }}>
                    {item.Description}
                  </TableCell>
                  <TableCell sx={{ display: "flex", justifyContent: "center" }}>
                    <Controls.ActionButton
                      color="primary"
                      onClick={() => {
                        openInPopup(item);
                      }}
                      sx={{ minWidth: "15%" }}
                    >
                      <EditOutlinedIcon fontSize="small" />
                    </Controls.ActionButton>
                    <Controls.ActionButton
                      color="secondary"
                      onClick={() =>
                        confirmDeleteObjective(item.Strategic_Objectives_ID)
                      }
                    >
                      <CloseIcon fontSize="small" />
                    </Controls.ActionButton>
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </TblContainer>
        <Popup
          title="Objective Form"
          openPopup={openPopupObjective}
          setOpenPopup={setOpenPopupObjective}
        >
          <ObjectiveForm
            recordForEdit={recordForEdit}
            onSubmit={() => setReloadObjective((prev) => !prev)}
            setOpenPopup={setOpenPopupObjective}
            showNotification={showNotification}
          />
        </Popup>

        <ConfirmationDialog
          open={openConfirmDialog}
          setOpen={setOpenConfirmDialog}
          DialogName="Delete Objectives Confirmation"
          DialogText="Are you sure you want to delete this Objective?"
          onConfirm={() => {
            handleConfirmDelete();
            showNotification({
              isOpen: true,
              message: "Objective deleted successfully",
              type: "success",
            });
          }}
          onClose={() => setOpenConfirmDialog(false)}
        />
        <Notification notify={notify} setNotify={setNotify} />
      </ContentContainer>
    </AppTheme>
  );
}
