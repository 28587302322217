import * as React from "react";
import { useNavigate } from "react-router-dom"; // Import useNavigate hook
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText"; // Import ListItemText
import Stack from "@mui/material/Stack";
import EmojiObjectsIcon from "@mui/icons-material/EmojiObjects";
import GroupIcon from "@mui/icons-material/Group";
import GppMaybeIcon from "@mui/icons-material/GppMaybe";
import MovingIcon from "@mui/icons-material/Moving";
import DownloadDoneIcon from "@mui/icons-material/DownloadDone";
import ReceiptLongIcon from "@mui/icons-material/ReceiptLong";
import DashboardIcon from "@mui/icons-material/Dashboard";
import Tooltip from "@mui/material/Tooltip"; // Import Tooltip
import { useDrawer } from "../../utils/DrawerProvider"; // Ensure correct context path

const mainListItems = [
  { text: "Dashboard", icon: <DashboardIcon />, path: "/dashboard" },
  { text: "Strategic Plan", icon: <ReceiptLongIcon />, path: "/strategicPlan" },
  { text: "Objectives", icon: <EmojiObjectsIcon />, path: "/objectives" },
  { text: "Initiatives", icon: <DownloadDoneIcon />, path: "/initiatives" },
  { text: "KPI", icon: <MovingIcon />, path: "/kpi" },
  { text: "KRI", icon: <GppMaybeIcon />, path: "/kri" },
];

const secondaryListItems = [
  { text: "Users", icon: <GroupIcon />, path: "/Users" },
];

export default function MenuContent() {
  const navigate = useNavigate();
  const { drawerWidth } = useDrawer(); // Get drawer width from context

  const handleNavigation = (path) => {
    navigate(path);
  };

  return (
    <Stack
      sx={{
        flexGrow: 1,
        p: 2,
        justifyContent: "space-between",
        mt: 1,
      }}
    >
      <List dense>
        {mainListItems.map((item, index) => (
          <ListItem
            key={index}
            disablePadding
            sx={{
              mb: 2,
              fontWeight: "lg",
              borderRadius: "12px",
              fontSize: "16px",
              display: "flex",
              justifyContent: drawerWidth <= 80 ? "center" : "flex-start", // Center icons if the drawer is narrow
              alignItems: "center",
            }}
          >
            <Tooltip title={item.text} arrow placement="right">
              <ListItemButton
                onClick={() => handleNavigation(item.path)}
                sx={{
                  "&:hover": {
                    backgroundColor: "#2E3650",
                    borderRadius: "8px", // Light gray hover effect
                  },
                  color: "#FAF9F6",
                  fontStyle: "bold",
                  fontSize: "1.2rem",
                  display: "flex",
                  alignItems: "center",
                  transition: "all 0.3s ease",
                  padding: drawerWidth === 230 ? "10px" : "12px 24px", // Adjust padding based on drawer width
                  justifyContent: "center", // Ensure the icon is centered in narrow drawer
                }}
              >
                <ListItemIcon sx={{ color: "#FAF9F6", minWidth: "auto" }}>
                  {item.icon}
                </ListItemIcon>
                {drawerWidth > 80 && (
                  <ListItemText
                    primary={item.text}
                    sx={{
                      fontSize: "16px", // Ensure this is applied directly
                      fontWeight: "medium",
                      color: "#FAF9F6",
                      "& .MuiTypography-root": {
                        fontSize: "16px", // This ensures that Typography inside ListItemText also gets the correct font size
                      },
                      marginLeft: "16px", // Add space between icon and text
                    }}
                  />
                )}
              </ListItemButton>
            </Tooltip>
          </ListItem>
        ))}
      </List>
      <List dense>
        {secondaryListItems.map((item, index) => (
          <ListItem
            key={index}
            disablePadding
            sx={{
              mb: 1,
              fontWeight: "lg",
              borderRadius: "12px",
              color: "#FAF9F6",
              display: "flex",
              justifyContent: drawerWidth <= 80 ? "center" : "flex-start", // Center icons if the drawer is narrow
              alignItems: "center",
            }}
          >
            <Tooltip title={item.text} arrow placement="right">
              <ListItemButton
                onClick={() => handleNavigation(item.path)}
                sx={{
                  "&:hover": {
                    backgroundColor: "#2E3650",
                    borderRadius: "8px", // Light gray hover effect
                  },
                  display: "flex",
                  alignItems: "center",
                  padding: drawerWidth === 230 ? "10px" : "12px 24px", // Adjust padding based on drawer width
                  justifyContent: "center", // Ensure the icon is centered in narrow drawer
                }}
              >
                <ListItemIcon sx={{ color: "#FAF9F6", minWidth: "auto" }}>
                  {item.icon}
                </ListItemIcon>
                {drawerWidth > 80 && (
                  <ListItemText
                    primary={item.text}
                    sx={{
                      fontSize: "16px", // Ensure this is applied directly
                      fontWeight: "medium",
                      color: "#FAF9F6",
                      "& .MuiTypography-root": {
                        fontSize: "16px", // This ensures that Typography inside ListItemText also gets the correct font size
                      },
                      marginLeft: "16px", // Add space between icon and text
                    }}
                  />
                )}
              </ListItemButton>
            </Tooltip>
          </ListItem>
        ))}
      </List>
    </Stack>
  );
}
