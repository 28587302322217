import React, { useState, useEffect, useMemo, useCallback } from "react";
import Controls from "../../components/assests/Controls";
import {
  TableBody,
  TableRow,
  TableCell,
  Toolbar,
  InputAdornment,
  TableHead,
  TableSortLabel,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import CloseIcon from "@mui/icons-material/Close";
import UseTable from "../../components/assests/UseTable";
import AppTheme from "../../Theme/AppTheme";
import axios from "axios";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";

const proxy = process.env.REACT_APP_PROXY;

// Styled components
const ContentContainer = styled("div", {
  shouldForwardProp: (prop) => prop !== "drawerWidth",
})(({ theme, drawerWidth }) => ({
  marginLeft: drawerWidth === 240 ? 240 : 0,
  transition: "margin-left 0.3s ease",
  width: `calc(100% - ${drawerWidth}px)`,
}));

const SearchInput = styled("div")(({ theme }) => ({
  flex: 1,
  marginRight: theme.spacing(1),
  minWidth: 400,
}));

const headCells = [
  { id: "Initiative_ID", label: "ID" },
  { id: "Objective_ID", label: "Objective" },
  { id: "Initiative_Name", label: "Initiative" },
  { id: "Description", label: "Description" },
  { id: "Department", label: "Department" },
  { id: "Start_Date", label: "Start Date" },
  { id: "End_Date", label: "End Date" },
  { id: "budget", label: "Budget" },
  { id: "Status", label: "Status" },
];

function Initiatives({ drawerWidth, ...props }) {
  const [initativeRecords, setinItativeRecords] = useState([]);
  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState("InitiativeId");
  const [objectives, setObjectives] = useState([]);
  const [departments, setDepartments] = useState([]);
  const [searchInput, setSearchInput] = useState("");
  const [values, setValues] = useState({ department: "" });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setValues({ ...values, [name]: value });
  };
  const departmentList = departments || [];

  const { TblContainer } = UseTable(initativeRecords, headCells);

  const fetchObjectives = useCallback(async () => {
    try {
      const response = await axios.get(`${proxy}/objective/getAllObjectives`);
      setObjectives(response.data.data);
    } catch (error) {
      console.error(error);
    }
  }, []);

  const fetchDepartments = useCallback(async () => {
    try {
      const response = await axios.get(`${proxy}/department/getDep`);
      setDepartments(response.data);
    } catch (error) {
      console.error(error);
    }
  }, []);

  useEffect(() => {
    fetchObjectives();
    fetchDepartments();
  }, [fetchObjectives, fetchDepartments]);

  const fetchInitiatives = useCallback(async () => {
    try {
      const response = await axios.get(`${proxy}/initiatives/getAllInitiative`);
      setinItativeRecords(response.data.data);
    } catch (error) {
      console.log(error);
    }
  }, []);

  useEffect(() => {
    fetchInitiatives();
  }, [fetchInitiatives]);

  const getObjectivetName = useCallback(
    (objectiveId) => {
      const objective = objectives.find(
        (objective) => objective.Strategic_Objectives_ID === objectiveId
      );
      return objective ? objective.Description : "Unknown";
    },
    [objectives]
  );

  useEffect(() => {
    // Fetch objectives
    const fetchObjectives = async () => {
      try {
        const response = await axios.get(`${proxy}/objective/getAllObjectives`);
        setObjectives(response.data.data);
      } catch (error) {
        console.error("Error fetching objectives:", error);
      }
    };
    fetchObjectives();
  }, []);

  const getDepartmentName = useCallback(
    (departmentId) => {
      const department = departments.find(
        (dep) => dep.Department_ID === departmentId
      );
      return department ? department.Department_Name : "Unknown";
    },
    [departments]
  );

  const formatDate = (dateString) => {
    if (!dateString) return "";
    const date = new Date(dateString);
    return new Intl.DateTimeFormat("en-GB", {
      day: "2-digit",
      month: "short",
      year: "numeric",
    }).format(date);
  };

  const sortedRecords = [...initativeRecords].sort((a, b) => {
    if (a[orderBy] < b[orderBy]) {
      return order === "asc" ? -1 : 1;
    }
    if (a[orderBy] > b[orderBy]) {
      return order === "asc" ? 1 : -1;
    }
    return 0;
  });

  const filteredRecords = useMemo(() => {
    return sortedRecords.filter((initiative) => {
      // Filter by search input
      const matchesSearchInput =
        (initiative.Description &&
          initiative.Description.toLowerCase().includes(
            searchInput.toLowerCase()
          )) ||
        (initiative.Initiative_Name &&
          initiative.Initiative_Name.toLowerCase().includes(
            searchInput.toLowerCase()
          )) ||
        (initiative.Objective_ID &&
          getObjectivetName(initiative.Objective_ID)
            .toLowerCase()
            .includes(searchInput.toLowerCase())) ||
        (initiative.Department_ID &&
          getDepartmentName(initiative.Department_ID)
            .toLowerCase()
            .includes(searchInput.toLowerCase())) ||
        (initiative.Status &&
          initiative.Status.toLowerCase().includes(
            searchInput.toLowerCase()
          )) ||
        (initiative.Start_Date &&
          formatDate(initiative.Start_Date)
            .toLowerCase()
            .includes(searchInput.toLowerCase())) ||
        (initiative.End_Date &&
          formatDate(initiative.End_Date)
            .toLowerCase()
            .includes(searchInput.toLowerCase())) ||
        (initiative.Initiative_ID &&
          initiative.Initiative_ID.toString().includes(searchInput));

      // Filter by selected department (check if department exists in the array of Department_IDs)
      const departmentIds = initiative.Department_IDs.split(",").map((id) =>
        parseInt(id, 10)
      );
      const matchesDepartment =
        !values.department ||
        departmentIds.includes(parseInt(values.department, 10));

      // Filter by selected objective (check if the initiative matches the selected objective)
      const matchesObjective =
        !values.Objective_ID || initiative.Objective_ID === values.Objective_ID;

      return matchesSearchInput && matchesDepartment && matchesObjective;
    });
  }, [
    sortedRecords,
    searchInput,
    values.department,
    values.Objective_ID,
    getObjectivetName,
    getDepartmentName,
  ]);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  return (
    <AppTheme {...props}>
      <ContentContainer drawerWidth={drawerWidth}>
        <Toolbar
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between", // Space evenly between elements
            maxWidth: "100%",
            position: "relative",
          }}
        >
          <SearchInput sx={{ flex: 1, maxWidth: "40%" }}>
            <Controls.Input
              sx={{
                width: "100%",
                height: 40,
                fontSize: "1rem",
              }}
              id="search-initiative-input"
              placeholder="Search Initiatives"
              value={searchInput}
              onChange={(e) => setSearchInput(e.target.value)}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <Controls.ActionButton
                      color="secondary"
                      onClick={() => setSearchInput("")}
                    >
                      <CloseIcon fontSize="small" />
                    </Controls.ActionButton>
                  </InputAdornment>
                ),
              }}
            />
          </SearchInput>
          <FormControl
            fullWidth
            variant="filled"
            sx={{
              flex: 1,
              maxWidth: "30%", // Limits the width of the select dropdown
              height: 40,
            }}
          >
            <InputLabel
              sx={{
                fontSize: "1rem",
                top: "-4px", // Aligns label with input placeholder
              }}
            >
              Objective
            </InputLabel>
            <Select
              placeholder="Objective"
              name="Objective"
              value={values.Objective_ID || ""}
              onChange={(e) =>
                setValues({ ...values, Objective_ID: e.target.value })
              }
              sx={{
                height: 40, // Same height as input
                padding: "10px 12px",
              }}
            >
              {objectives.map((objective) => (
                <MenuItem
                  key={objective.Strategic_Objectives_ID}
                  value={objective.Strategic_Objectives_ID}
                >
                  {objective.Description}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <FormControl
            fullWidth
            variant="filled"
            sx={{
              flex: 1,
              maxWidth: "30%", // Limits the width of the select dropdown
              height: 40,
            }}
          >
            <InputLabel
              sx={{
                fontSize: "1rem",
                top: "-4px", // Aligns label with input placeholder
              }}
            >
              Department
            </InputLabel>
            <Select
              placeholder="Department"
              name="department"
              value={
                departmentList.some(
                  (dept) => dept.Department_ID === values.department
                )
                  ? values.department
                  : ""
              }
              onChange={handleInputChange}
              sx={{
                height: 40, // Same height as input
                padding: "10px 12px",
              }}
            >
              {departments.map((dept) => (
                <MenuItem key={dept.Department_ID} value={dept.Department_ID}>
                  {dept.Department_Name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Toolbar>

        <TblContainer>
          <TableHead>
            <TableRow>
              {headCells.map((headCell) => (
                <TableCell
                  sx={{ bgcolor: "#f4f6fa" }}
                  key={headCell.id}
                  sortDirection={orderBy === headCell.id ? order : false}
                >
                  {headCell.disableSorting ? (
                    headCell.label
                  ) : (
                    <TableSortLabel
                      active={orderBy === headCell.id}
                      direction={orderBy === headCell.id ? order : "asc"}
                      onClick={(event) => handleRequestSort(event, headCell.id)}
                    >
                      {headCell.label}
                    </TableSortLabel>
                  )}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {filteredRecords.map((item, index) => {
              // Split the Department_IDs into an array of department IDs
              const serialNumber = index + 1;

              const departmentIds = item.Department_IDs.split(",").map((id) =>
                parseInt(id, 10)
              ); // Fetch the department names for each ID and log the results
              const departmentNames = departmentIds.map((id) => {
                const departmentName = getDepartmentName(id);
                return departmentName;
              });

              // Join the department names into a string if there are multiple
              const departmentString = departmentNames.join(", ");

              return (
                <TableRow hover key={item.Initiative_ID}>
                  <TableCell align="center">{serialNumber}</TableCell>
                  <TableCell align="center">
                    {getObjectivetName(item.Objective_ID)}
                  </TableCell>
                  <TableCell align="center">{item.Initiative_Name}</TableCell>
                  <TableCell align="center">{item.Description}</TableCell>
                  <TableCell align="center">
                    {departmentString || "Unknown"}
                  </TableCell>
                  <TableCell align="center">
                    {formatDate(item.Start_Date)}
                  </TableCell>
                  <TableCell align="center">
                    {formatDate(item.End_Date)}
                  </TableCell>
                  <TableCell align="center">{item.budget}</TableCell>
                  <TableCell align="center">{item.Status}</TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </TblContainer>
      </ContentContainer>
    </AppTheme>
  );
}

export default Initiatives;
